import * as React from 'react'
import { Container } from '../components/container'
import { SectionTitle } from '../components/section-title'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { ArticleHighlighted } from '../components/blog/article-highlighted'
import { ArticleCard } from '../components/blog/article-card'

const BlogListPage = ({ data }) => {
  const posts = data.posts.nodes
  const { title, seoMetaTags, slug, highlightedPost } = data.page

  const updatedLinks = data.links.nodes.reduce(
    (newObj, item) => ((newObj[item.locale] = item.slug), newObj),
    {}
  )

  return (
    <Layout hrefLangLinks={updatedLinks} seoMetaTags={seoMetaTags}>
      <Container>
        <SectionTitle title={title} main={true} />
        <div className="max-w-7xl mx-auto">
          <ArticleHighlighted baseUrl={slug} post={highlightedPost} />
        </div>

        <div className="grid grid-cols-1 gap-6 mx-auto sm:grid-cols-3 mt-6">
          {posts.map(post => (
            <ArticleCard baseUrl={slug} post={post} />
          ))}
        </div>
      </Container>
    </Layout>
  )
}

export default BlogListPage

export const blogQuery = graphql`
  query ($locale: String!) {
    page: datoCmsBlogPage(locale: { eq: $locale }) {
      title
      highlightedPost {
        id
        title
        content
        contentSummary
        imageSummary {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
        slug
        meta {
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
    }

    links: allDatoCmsBlogPage {
      nodes {
        slug
        locale
      }
    }

    posts: allDatoCmsBlogPost(
      filter: { locale: { eq: $locale } }
      sort: { fields: [meta___createdAt], order: DESC }
    ) {
      nodes {
        id
        title
        content
        contentSummary
        imageSummary {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
        slug
        author {
          avatar {
            url
          }
          name
        }
        meta {
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`
