import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'

export function ArticleCard({ baseUrl, post }) {
  const { t } = useTranslation()
  return (
    <div className="group relative flex sm:block text-left shadow-lg rounded-lg" key={post.slug}>
      <Link
        className="has-text-black space-y-3 "
        to={t('link-base') + baseUrl + '/' + post.slug}
      >
        <GatsbyImage
          image={{ ...post.imageSummary.gatsbyImageData }}
          alt="blog post image"
          className="w-full object-cover h-fit md:w-full md:h-auto rounded-t-lg"
        />

        <div className="relative space-y-2 font-light p-6">
          <div className="flex">
            <p className="text-sm">{post.meta.publishedAt}</p>
          </div>

          <h2 className="font-serif sm:text-2xl text-lg font-medium group-hover:text-abel-green">
            {post.title}
          </h2>

          <p className="text-base pt-3 hidden sm:block">
            {post.contentSummary}
          </p>
        </div>
      </Link>
    </div>
  )
}
