import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'

export function ArticleHighlighted({ baseUrl, post }) {
  const { t } = useTranslation()
  return (
    <div className="grid grid-cols-1 md:grid-cols-5 md:gap-20">
      <div className="col-span-3">
        <GatsbyImage
          image={{ ...post.imageSummary.gatsbyImageData }}
          alt="blog post image"
          className="rounded-2xl md:rounded"
        />
      </div>

      <div className="col-span-2 my-auto">
        <div className="flex pb-2 pt-2 ">
          <div className="text-left my-auto flex-shrink-0">
            <p className="font-light  lg:text-lg text-base pr-2">
              {post.meta.publishedAt} | 30 min read
            </p>
          </div>
        </div>
        <h2 className="text-left font-serif font-light text-3xl md:text-5xl pt-6">
          <Link
            className="has-text-black hover:text-abel-green"
            to={t('link-base') + baseUrl + '/' + post.slug}
          >
            {post.title}
          </Link>
        </h2>
        <p className="font-light text-left text-lg pt-5">
          {post.contentSummary}
        </p>
      </div>
    </div>
  )
}
